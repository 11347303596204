@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/spacings";
@use "$styles/variables/shadows";
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";

@include mediaqueries.upToSmall {
    .toggleButton {
        display: none;
    }
}
@include mediaqueries.upToMedium {
    .row {
        row-gap: spacings.$M;
    }
}

.singleCard {
    display: flex;
}

.card {
    display: flex;
    flex-direction: column;
    row-gap: spacings.$S;
    padding: spacings.$M spacings.$S;
    height: 100%;
    background: colors.$white;
    box-shadow: shadows.$elevationLevel3BoxShadow;

    @include mediaqueries.mediumUp {
        padding: spacings.$M spacings.$L;
    }

    ul {
        margin: 0;
        width: calc(100% - 20px);

    }

    li {
        margin-bottom: spacings.$S;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @supports (symbols: url("/icons/general/check-circle.svg")) {
        @counter-style check-circles {
            system: cyclic;
            symbols: url("/icons/general/check-circle.svg");
            color: colors.$green300;
            suffix: " ";
        }
        ul {
            list-style: check-circles;
        }
    }
    @supports not (symbols: url("/icons/general/check-circle-broken.svg")) {
        ul {
            list-style-type: none;

            li {
            position: relative;

            &::before {
                content: '';
                background: colors.$green300;
                mask: url('/icons/general/check-circle-broken.svg');
                width: 24px;
                height: 24px;
                display: inline-flex;
                position: absolute;
                left: -24px - 12px;
            }
            }
        }
    }
    
    p {
        margin-top: 0;
    }
}

.heading {
    @include typography.headlinesH6TypographyStyles;
    margin: 0;
    
    @include mediaqueries.mediumUp {
        @include typography.headlinesH4TypographyStyles;
    }
}

.content {
    flex: auto;    
    color: colors.$neutral600;
    b {
        @include typography.labelSTypographyStyles;
        @include mediaqueries.mediumUp {
            @include typography.labelMTypographyStyles;
        }
        color: colors.$neutral600;
    }
}

.footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
}

.price {
    @include typography.labelSTypographyStyles;
    text-align: right;
}

.priceValue {
    @include typography.headlinesH6TypographyStyles;

    @include mediaqueries.mediumUp {
        @include typography.headlinesH5TypographyStyles;
    }
}
