@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/radii';
@use '$styles/variables/spacings';

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 456px;
  margin: 0 auto;

  background-color: colors.$purple100;
  border-radius: radii.$L;
  padding: spacings.$M spacings.$L spacings.$S;

  h6 {
    text-align: center;
  }
}

.logo {
  position: absolute;
  top: -25px;
  left: calc(50% - 25px);
}

.reviewContainer {
  margin-top: 24px;
  text-align: center;
}

.googleReviewsSummary {
  display: flex;
  justify-content: center;

  a {
    gap: 5px;
  }

  p {
    color: colors.$neutral800 !important;
    margin: 0;
  }
}
