@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/spacings";
@use "$styles/variables/colors";
@use "$styles/tools/extends/typography";
@use '$styles/mediaqueries';

.component {
  padding: spacings.$S spacings.$M;
  background-color: colors.$green100;
  border: 1px solid colors.$green200;

  @include mediaqueries.mediumUp {
    padding: spacings.$XS spacings.$M;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}
  
.priceMatchTitle {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  color: colors.$green700; 
}
  
.priceMatchTitleHeading {
  @extend %labelMTypographyStyles;
  letter-spacing: 0;
  margin: 0;
}
  
.priceMatchText {
  @extend %paragraphSTypography;
  color: colors.$green400;
}