@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';

$columnGap: 48px;

.component {
    background-color: colors.$purple100;
}

.services {
  background: colors.$white;
  padding: 37px 20px;

  @include mediaqueries.largeUp {
    padding: 40px 56px;
  }

  @include mediaqueries.extraSmallOnly {
    border-radius: 0;
  }
}

.listWrapper {
  padding: 0;
  margin: 39px 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: $columnGap;
  row-gap: 28px;
}

.listItem {
  flex: 1 1 calc((100% / 2) - $columnGap);

  @include mediaqueries.largeUp {
    flex: 0 1 calc((100% / 4) - $columnGap);
  }
}

.title {
  max-width: 847px;
  color: colors.$neutral800;

  @include mediaqueries.upToLarge {
    @include typography.headlinesH4TypographyStyles;
  }
}

.showAllMakesBtn {
  padding: 0 !important;
}

.wrapper {
  @include mediaqueries.extraSmallOnly {
    padding: 0;
  }
}

.serviceLink {
  @include typography.paragraphLTypography;
  color: colors.$neutral600;
}