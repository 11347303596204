@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';

.component {
  width: 100%;
  margin: spacings.$XXL 0;
  @include mediaqueries.mediumUp {
    margin: 56px 0;
  }
}
.heading {
  color: colors.$neutral600;
  text-align: center;
  @include typography.headlinesH6TypographyStyles;
  margin-bottom: spacings.$S;
  @include mediaqueries.smallUp {
    @include typography.headlinesH4TypographyStyles;
    margin-bottom: spacings.$M;
  }
}
.card {
  text-align: center;
  background-color: colors.$white;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  &:hover {
    border: 2px solid colors.$purple500;
    overflow: hidden;
  }
}
.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: spacings.$S spacings.$S spacings.$M;
  gap: spacings.$S;
}
.label {
  text-align: left;
  color: colors.$neutral600;
  @include typography.labelLTypographyStyles;
  font-weight: 600;
}
.price {
  text-align: right;
  white-space: nowrap;
  color: colors.$neutral400;
  @include typography.paragraphSTypography;
  @include mediaqueries.smallUp {
    @include typography.paragraphMTypography;
  }
}
.modalHeading {
  @include typography.headlinesH5TypographyStyles;
  color: colors.$purple800;
  margin-bottom: spacings.$L;
  text-align: center;
}
.imageContainer {
  border-radius: 12px;
  overflow: hidden;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  margin-left: -2px;
  margin-top: -2px;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.componentMode {
  @include mediaqueries.smallUp {
    .card {
      display: flex;
      flex-direction: row;
      > .imageContainer {
        width: 204px;
        height: 144px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: -2px;
        > .image {
          object-fit: cover;
        }
      }
      > .bottom {
        flex-direction: column;
        justify-content: center;
        padding: spacings.$M spacings.$S;
        gap: spacings.$XXS;
        >.price {
          text-align: left;
          min-height: 36px;
        }
        >.label {
          min-height: 36px;
        }
      }
    }
  }
}
