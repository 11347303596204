@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/elevation';
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";
@use "$styles/variables/spacings";

.component {
  background-color: colors.$white;
}

.card {
  gap: 19px;
  margin-bottom: spacings.$XL;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mediaqueries.mediumUp {
    gap: spacings.$L;
    margin-bottom: 96px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.imgCard {
  padding-top: 30px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 352px;
  margin: auto;

  img {
    width: 264px;
    height: 495px;
  }

  @include mediaqueries.mediumUp {
    padding-top: spacings.$M;
    margin: unset;
    height: 454px;

    img {
      width: 310px;
      height: 594px;
    }
  }
}

.stepNumber {
  width: 42px;
  height: 42px;
  border-radius: 14px;
  font-family: Champ;
  font-style: normal;
  font-size: 26px;
  font-weight: 600;
  line-height: 40px; 
  margin-bottom: 13px;
  display: flex;
  span {
    margin: auto;
  }

  @include mediaqueries.mediumUp {
    margin-bottom: spacings.$M;
    width: 62px;
    height: 62px;
    font-size: 38px;
    border-radius: 18px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mediaqueries.mediumUp {
    align-items: flex-start;
    margin: auto 0;
    flex: 1;
  }
}

.imgCardContainer {
  width: 100%;
  position: relative;
  @include mediaqueries.mediumUp {
    flex: 1;
  }
}

.confetti {
  position: absolute;
  bottom: 0;
  right: -50px;
  @include mediaqueries.upToMedium {
    max-height: 450px;
  }
}

.heading {
  @include typography.headlinesH3TypographyStyles;
  color: colors.$neutral800;
  margin-bottom: spacings.$XXS;
  text-align: center;
  @include mediaqueries.mediumUp {
    margin-bottom: spacings.$M;
    text-align: left
  }
}

.description {
  @include typography.paragraphLTypography;
  color: #0C3D55;
  margin: 0;
  text-align: center;
  @include mediaqueries.mediumUp {
    text-align: left
  }
}

.card {
  &:nth-child(even) {
    @include mediaqueries.mediumUp {
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
      .stepNumber,
      .heading,
      .description {
        margin-left: spacings.$M;
      }
    }
    .stepNumber {
      background-color: colors.$purple500;
      color: colors.$white;
    }
    .imgCard {
      background-color: colors.$purple500;
      @include mediaqueries.mediumUp {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
  &:first-child {
    .imgCard {
      background-color: colors.$brandCyan;
    }
    .stepNumber {
      background-color: colors.$brandCyan;
      color: colors.$neutral800;
      position: relative;
      img {
        position: absolute;
        top: -165px;
        left: -208px;
        @include mediaqueries.mediumUp {
          display: none;
        }
      }
    }
  }
  &:nth-child(3) {
    .imgCard {
      background-color: colors.$purple800;
    }
    .stepNumber {
      background-color: colors.$purple800;
      color: colors.$white;
    }
  }
  &:nth-child(odd) {
    @include mediaqueries.mediumUp {
      .stepNumber,
      .heading,
      .description {
        margin-right: spacings.$M;
      }
    }
  }
}