@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';

.pageHeading {
  @include typography.headlinesH3TypographyStyles;
  color: colors.$neutral800;
    margin-bottom: 0;
    text-align: center;
  @include mediaqueries.smallUp {
    @include typography.headlinesH2TypographyStyles;
    color: colors.$purple800;
  }
}
