@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/spacings";
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";

.background {
  background-color: colors.$white;
  padding: spacings.$L spacings.$M;

  @include mediaqueries.mediumUp {
    padding: spacings.$XL spacings.$L;
  }
}

.rowContainer {
  padding: 0;

  @include mediaqueries.largeUp {
    padding: 0 spacings.$XL;
  }
}

.row {
  padding: 0;

  @include mediaqueries.mediumUp {
    padding: 0 spacings.$XL;
  }
}

.card {
  margin: 0px auto;

  @include mediaqueries.mediumUp {
    max-width: 100%;
  }

  &.layout1 {
    background-color: colors.$cyan100;
  }

  &.layout2 {
    background-color: colors.$green200;

    @include mediaqueries.mediumUp {
      background-image: linear-gradient(to right, colors.$green200, colors.$green100)
    }
  }
}

.heading {
  margin: 0;
  margin-bottom: 0px !important;
  padding: 0;
  text-align: center;
  color: colors.$neutral800;
  @include typography.headlinesH6TypographyStyles;

  @include mediaqueries.smallUp {
    @include typography.headlinesH4TypographyStyles;
  }

  @include mediaqueries.mediumUp {
    text-align: left;
  }
}

.body {
  margin: 0;
  padding: 0;
  text-align: center;
  color: colors.$neutral800;
  @include typography.paragraphMTypography;

  @include mediaqueries.smallUp {
    @include typography.paragraphLTypography;
  }

  @include mediaqueries.mediumUp {
    text-align: left;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: spacings.$S;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: spacings.$M spacings.$S;

  @include mediaqueries.smallUp {
    padding: spacings.$L spacings.$XXL;
  }

  @include mediaqueries.mediumUp {
    align-items: flex-start;
    padding: spacings.$M spacings.$S;
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageStart {
  @include mediaqueries.mediumUp {
    order: 1;
  }
}
.row .contentContainer .link {
  padding: 0;
}

.link {
  padding: 0;
  @include typography.paragraphMTypography;

  @include mediaqueries.smallUp {
    @include typography.paragraphLTypography;
  }

  &:hover {
    text-decoration: underline;

    &:after {
      display: none !important;
    }
  }
}
