@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';

.component {
  margin-top: spacings.$XXL;
  @include mediaqueries.mediumUp {
    margin-top: 56px;
  }

  &:first-child {
    margin-top: spacings.$S;
  }
  &.componentMode {
    margin-top: spacings.$M;
    margin-bottom: spacings.$M;
    @include mediaqueries.mediumUp {
      margin-top: spacings.$L;
      margin-bottom: 0;
    }
  }

}

.cards {
  display: grid;
  gap: spacings.$S;
  margin-top: 20px;

  @include mediaqueries.mediumUp {
    gap: spacings.$L;
  }
}

.twoColumns {
  grid-template-columns: repeat(2, 1fr);
}

.threeColumns {
  grid-template-columns: repeat(2, 1fr);

  @include mediaqueries.mediumUp {
    grid-template-columns: repeat(3, 1fr);
  }
}
.heading {
  color: colors.$neutral600;
  text-align: center;
  @include typography.headlinesH6TypographyStyles;
  margin-bottom: spacings.$S;
  @include mediaqueries.smallUp {
    @include typography.headlinesH4TypographyStyles;
    margin-bottom: spacings.$M;
  }
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: colors.$white;
  border: 2px solid transparent;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.card:hover {
  border-color: colors.$purple500;
  cursor: pointer;
}

.label {
  margin: 0;
  margin-top: spacings.$XS;
  text-align: center;
  @include typography.labelSTypographyStyles;
  @include mediaqueries.mediumUp {
    @include typography.labelMTypographyStyles;
  }
  color: colors.$neutral800;
}

.price {
  margin: 0;
  margin-top: spacings.$XXS;
  @include typography.paragraphXSTypography;
  @include mediaqueries.mediumUp {
    @include typography.paragraphSTypography;
  }
  color: colors.$neutral400;
}

.seeAllRepairs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: spacings.$M;
  a, span {
    @include typography.labelMTypographyStyles;
    text-decoration: underline;
    text-decoration-color: colors.$purple500;
    color: colors.$purple500;
    margin-right: spacings.$XXS;
    cursor: pointer;
  }
}

.modalHeading {
  @include typography.headlinesH5TypographyStyles;
  color: colors.$purple800;
  margin-bottom: spacings.$L;
  text-align: center;
}



.seeServicesButton {
  padding: 0px !important;
  margin-top: spacings.$XXS;
  height: 40px;
  width: 40px;
}

.partnerCard {
  margin-bottom: values.$space6;
  border-radius: values.$space6;
  display: block;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

  .partnerCardIcon {
    margin-left: auto;
    width: 76px;
    height: 76px;
  }

  .label {
    text-align: left;
    margin-top: 0px;
  }

  .price {
    color: colors.$neutral600;
  }
}

.arrowLink {
  margin-top: 10px;
  background-color: colors.$purple500;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  color: white;
  display: block;
  line-height: 29px;
}