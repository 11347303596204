@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/elevation';
@use '$styles/variables/spacings';
@use '$styles/variables/radii';
@use '$styles/variables/fonts';
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';

.component {
  background-color: var(--background-color);

  :global(.current) {
    background-color: var(--indicator-color);
  }
  @include mediaqueries.upToMedium {
    :global(.ScrollIndicator) {
      transform: translateY(-48px);
    }
    :global(.ArrowButton) {
      transform: translateY(-78px);
    }
  }
  @include mediaqueries.mediumUp {
    :global(.selected) {
      > .carouselItem {
        opacity: 1;
      }
    }
    :global(.ArrowButton) {
      width: 64px;
      height: 64px;

      &:first-of-type {
        transform: translateX(8px);
      }
      &:last-of-type {
        transform: translateX(-8px);
      }
      svg {
        transform: scale(1.6);
      }
    }
  }
}
@include mediaqueries.mediumUp {
  .carousel {
    &::before,
    &::after {
      content: '';
      display: block;
      height: 100%;
      width: 40px;
      position: absolute;
      top: 0;
      z-index: elevation.$level1 - 1;
    }
    &::before {
      left: 0;
      background-image: linear-gradient(90deg, var(--background-color), transparent);
    }
    &::after {
      right: 0;
      background-image: linear-gradient(-90deg, var(--background-color), transparent);
    }
  }
}
.heading {
  text-align: center;
  color: colors.$neutral800;
  @include mediaqueries.mediumUp {
    @include typography.headlinesH2TypographyStyles;
    margin: 0 auto spacings.$XS;
  }
}

.tagline {
  text-align: center;

  @include mediaqueries.upToMedium {
    display: none;
  }

  p {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.44px;
    color: colors.$neutral800;
    margin: 0;

    u {
      text-decoration: none;
      position: relative;

      &::after {
        content: url('/img/svg/squiggle-small.svg');
        position: absolute;
        left: 50%;
        translate: -50% 0;
        top: calc(50% + 4px);
      }
    }
  }
}

@include mediaqueries.upToMedium() {
  :global(.Carousel).carousel {
    > button {
      top: unset;
      bottom: 122px;
    }
  }
}

.carouselItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  transition: opacity 500ms ease-in-out;
  @include mediaqueries.upToMedium {
    padding-bottom: spacings.$XXL;
  }
  @include mediaqueries.mediumUp {
    opacity: 0.4;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
    column-gap: spacings.$L;
  }
}

.carouselItemText {
  @include mediaqueries.upToMedium {
    text-align: center;
    margin: 0 auto spacings.$S;
  }

  @include mediaqueries.mediumUp {
    transform: translate(0, 95px);
  }

  p {
    @include typography.paragraphMTypography;
    margin: 0;
    color: colors.$neutral400;

    @include mediaqueries.mediumUp {
      @include typography.paragraphLTypography;
      color: colors.$neutral600;
    }
  }
}

.carouselItemTitle {
  margin: 0 auto spacings.$XXS;
  @include mediaqueries.mediumUp {
    @include typography.headlinesH4TypographyStyles;
    margin-bottom: spacings.$S;
    display: flex;
    flex-direction: column;
    row-gap: spacings.$S;
  }
}

.carouselItemImage {
  display: flex;
  align-items: flex-end;
  width: calc(100% - 30px);
  height: 278px;
  max-width: 363px;
  position: relative;
  overflow: hidden;

  @include mediaqueries.mediumUp {
    height: 326px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color);
    border-radius: radii.$L;

    @include mediaqueries.mediumUp {
      height: 250px;
    }
  }
}

.image {
  width: 242px;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  > img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include mediaqueries.mediumUp {
    top: 0;
    width: 240px;
  }
}

.stepNumber {
  @include mediaqueries.upToMedium {
    &::after {
      content: '.';
    }
  }

  @include mediaqueries.mediumUp {
    @include typography.headlinesH4TypographyStyles;
    margin-bottom: 0;
    margin-right: 7px;
    font-family: fonts.$campaignFontFamily;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 3px;
    border-radius: 15px;
    width: 48px;
    height: 46px;
  }
}
.confetti {
  display: none;
  @include mediaqueries.upToMedium {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 517px;
    height: 426px;
  }
}

:export {
  colorBrandPurple: colors.$brandPurple;
  colorBrandDarkPurple: colors.$brandDarkPurple;
  colorBrandCyan: colors.$brandCyan;
  colorBrandBrightGreen: colors.$brandBrightGreen;
  colorWhite: colors.$white;
}
