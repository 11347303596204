@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/variables/radii';
@use '$styles/variables/spacings';
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';

.container {
  padding: spacings.$M spacings.$S;
  border: 0.5px solid colors.$neutral200;
  width: 100%;
  nav {
    display: flex;
    flex-direction: column;
    gap: spacings.$S;
  }
}

.questions {
  list-style: none;
  margin-block-start: spacings.$XXS;
  margin-block-end: 0;
  padding-inline-start: 0;
  li {
    a {
      display: block;
      padding: spacings.$XXS spacings.$XS;
      @include typography.paragraphSTypography;
      @include mediaqueries.mediumUp {
        @include typography.paragraphMTypography;
      }
      text-decoration: underline;
      color: colors.$neutral600;
      &:hover {
        background-color: colors.$purple100;
        border-radius: radii.$XS;
        color: colors.$purple600;
      }
    }
  }
}
.heading {
  display: flex;
  flex-direction: row;
  gap: spacings.$S;
  label {
    font: fonts.$labelL;
    font-weight: 600;
  }
}
.categoryIcon {
  filter: brightness(0%);
}
