@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/spacings";
@use "$styles/variables/elevation";
@use "$styles/variables/shadows";
@use "$styles/variables/radii";
@use '$styles/mediaqueries';

.component {
    &:global(.HUD) {
        position: relative;
    }
}

.openButton {
    position: absolute;
    bottom: spacings.$M;
    right: spacings.$M;
    z-index: elevation.$modals;
    box-shadow: shadows.$elevationLevel4BoxShadow;
    background-color: transparentize($color: colors.$red300, $amount: 0.2);
    &:hover {
        background-color: transparentize($color: colors.$red500, $amount: 0.2);
    }
}

.cmsKey {
    padding: spacings.$S 0;

    &:not(:last-child) {
        margin-bottom: spacings.$S;
    }
}

.propName {
    font-weight: 600;
}

.valueDisclosure {
    padding: spacings.$XS;
    margin-top: spacings.$XXS;
    border: 1px solid colors.$purple100;
    border-radius: radii.$S;
    color: colors.$neutral600;

    &[open] summary {
        border-bottom: 1px solid colors.$purple100;
        margin-bottom: spacings.$S;
    }
}

.valueDisclosureContent {
    text-indent: spacings.$S;
}