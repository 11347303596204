@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';

.arrowButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 47px 0px rgba(0, 0, 0, 0.20), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  color: colors.$purple500;

  &:focus {
    outline: none;
  }

  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: colors.$neutral100;
  }
}

.left {
  left: 0;

  > .icon {
    transform: rotate(180deg);
  }
}

.right {
  right: 0;
}
