@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/mediaqueries';

.call-us-cta-mobile {
  @include mediaqueries.smallUp {
    display: none !important;
  }
}

.call-us-cta-desktop {
  @include mediaqueries.upToSmall {
    display: none !important;
  }

  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.white {
  color: colors.$white;
}

.purple {
  color: colors.$purple500;
}

.desktopIcon {
  margin-right: 8px;
}
