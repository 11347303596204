@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/variables/spacings';

.component {
  padding: spacings.$XXXS 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-weight: 600;
  margin-right: spacings.$XXS;
  margin-top: 0;
  margin-bottom: 0;

  &--small {
    font: fonts.$labelXS;
  }

  &--large {
    font: fonts.$labelS;
  }
}

.lightVersion {
  color: colors.$neutral300;
}

.darkVersion {
  color: colors.$neutral800;
}
