@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/containerqueries';
@use '$styles/variables/elevation';

.carouselContainer {
  @include containerqueries.makeContainer(carouselContainer);
  position: relative;
  display: flex;
  flex-direction: column;

  .carouselArrow {
    top: calc(50% - 14px - 20px);
    transform: translateY(0);
    z-index: elevation.$level1;
  }
}
.carouselContainer {
  > .carouselArrowOff {
    display: none;
  }
}
.carouselViewPort {
  box-sizing: border-box;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  &:global(.suspend-snap) {
    scroll-snap-type: none;
  }
}

.carouselRibbon {
  width: min-content;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: var(--gap);
}

.carouselItem {
  width: calc(100cqw - ((var(--mobile-side-padding) * 2) + var(--gap)));
  margin: 5px 0;
  scroll-snap-align: center;
  scroll-snap-stop: always;

  @include containerqueries.upToMedium(carouselContainer) {
    &:first-child {
      margin-left: var(--mobile-peek);
      scroll-snap-align: end;
    }
    &:last-child {
      margin-right: var(--mobile-peek);
      scroll-snap-align: start;
    }
  }
}

.carouselIndicator {
  align-self: center;
  margin-top: 15px;
}

@include containerqueries.smallUp(carouselContainer) {

  .carouselItem {
    width: calc((100cqw/var(--desktop-visible-items)) - (var(--gap) + var(--peek)));
    margin: 6px 0;

    &:first-child {
      margin-left: 1px;
    }
    &:last-child {
      margin-right: 1px;
    }
  }
  .carouselIndicator {
    margin-top: 18px;
    &.carouselIndicatorOff {
      display: none;
    }
  }
}

@include containerqueries.upToMedium(carouselContainer) {
  .carouselRibbon {
    padding-left: var(--mobile-offset);
    padding-right: var(--mobile-offset);
  }
}

@include containerqueries.mediumUp(carouselContainer) {
   
  .carouselRibbon {
    gap: var(--desktop-gap);
  }
  
  .carouselRibbon {
    padding-left: var(--desktop-offset);
    padding-right: var(--desktop-offset);
  }
  .carouselItem {
    width: calc((100cqw/var(--desktop-visible-items)) - (var(--desktop-gap) + var(--peek)));
    max-width: var(--desktop-item-max-width);
  }
}