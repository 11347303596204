@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';
@use '$styles/variables/radii';
@use '$styles/variables/colors';

.component {
  background: colors.$white;
  padding-top: spacings.$M;

  @include mediaqueries.mediumUp {
    padding-top: unset;
  }
}

.Col {
  display: flex;
  justify-content: center;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: spacings.$M;
  width: 100%;
  max-width: 659px;

  @include mediaqueries.smallUp {
    flex-direction: row;
  }
}

.card {
  flex: 1;
  padding: spacings.$S spacings.$XXXS;
  border-radius: radii.$XS;
  text-align: center;
  align-content: center;

  > div {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: spacings.$XXS;
  }

  .title {
    @include typography.labelMTypographyStyles;
    margin: 0;
    letter-spacing: -0.16px;

    > img,
    > span {
      vertical-align: middle;
    }
  }

  .priceInfo {
    display: inline-flex;
    align-items: center;
    gap: spacings.$XXXS;

    .priceRange {
      @include typography.headlinesH6TypographyStyles;
      margin-bottom: 0;
    }
  }

  &.fixterCard {
    color: colors.$white;
    border-radius: radii.$S;
    background: linear-gradient(300deg, #795bfd 0%, colors.$purple500 100.39%);

    @include mediaqueries.smallUp {
      // Display as second card
      order: 1;
    }

    .priceInfo {
      margin-top: spacings.$XXXS;

      .saveAmount {
        @include typography.labelXSTypographyStyles;
        color: colors.$green600;
        padding: spacings.$XXXS spacings.$XXS;
        border-radius: radii.$S;
        background: colors.$green200;
      }
    }

    ul {
      display: inline-flex;
      flex-direction: column;
      gap: spacings.$XXS;
      margin: 0;
      padding: 0;
      list-style: none;
      @include typography.paragraphSTypography;

      > li {
        display: flex;
        align-items: center;
        gap: spacings.$XXS;
      }
    }
  }

  &.dealerCard {
    border: 1px solid colors.$neutral200;
    background: colors.$neutral100;

    > div {
      @include mediaqueries.upToSmall {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .priceInfo {
      .priceRange {
        @include mediaqueries.smallUp {
          @include typography.headlinesH5TypographyStyles;
          margin-bottom: 0;
        }
      }
    }
  }
}

.modalPriceRanges {
  display: none;

  @include mediaqueries.smallUp {
    display: flex;
    flex-direction: column;
    gap: spacings.$XS;
    margin: spacings.$L 0 spacings.$XS 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
    padding: spacings.$S spacings.$XS;
    border-radius: radii.$XS;
    background: colors.$neutral100;

    > span:last-child {
      font-weight: 600;
    }

    &:first-child {
      color: colors.$white;
      background: linear-gradient(300deg, #795bfd 0%, colors.$purple500 100.39%);
    }
  }
}

.InfoModalIcon {
  cursor: pointer;
}

.InfoModalDrawer {
  @include mediaqueries.upToSmall {
    padding: spacings.$S spacings.$M;
  }
  @include mediaqueries.smallUp {
    width: 420px;
  }
}
