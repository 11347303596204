@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/variables/fonts';

.component {
  .heading {
    @include typography.headlinesH4TypographyStyles;
    margin-bottom: spacings.$M;
    max-width: 1000px;

    @include mediaqueries.smallUp {
      @include typography.headlinesH3TypographyStyles;
      margin-bottom: spacings.$M;
    }
  }

  table {
    width: 100%;
    @include mediaqueries.upToSmall {
      width: calc(100% + spacings.$XL);
      translate: -20px;
    }
  }

  th,
  td {
    padding: 16px 20px;

    @include mediaqueries.smallUp {
      padding: 16px 24px;
    }
  }

  th {
    background-color: colors.$purple600;
    color: colors.$white;
    font-size: 18px;
    font-weight: 600;
    font-family: fonts.$headlinesFontFamily;

    @include mediaqueries.smallUp {
      font-size: 22px;
    }
  }

  tr:nth-child(odd) {
    background-color: colors.$neutral100;

    @include mediaqueries.smallUp {
      background-color: colors.$purple100;
    }
  }
  tr:nth-child(even) {
    background-color: colors.$white;
  }

  tr:nth-child(2) td {
    padding-top: 32px;
  }

  .getQuote {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.leftCol {
  width: 60%;
  text-align: left;

  @include mediaqueries.smallUp {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.rightCol {
  width: 40%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;

  @include mediaqueries.smallUp {
    font-size: 18px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.limitedRows {
  position: relative;
  &:has(+ :global(.toggle)) {
    tr:nth-child(n + 9) {
      display: none;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      height: 200px;
      background: linear-gradient(to top, colors.$white, transparent);
    }
  }
}
.toggleLink {
  text-align: center;
  a.toggleAnchor {
    &:link,
    &:visited,
    &:hover,
    &:active {
      &[href] {
        color: colors.$neutral400;
        cursor: pointer;
      }
    }
  }
}
