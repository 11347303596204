@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";

.scrollIndicator {
  $height: 14px;

  box-sizing: border-box;
  list-style: none;
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
  height: $height;
  padding: 4px;
  margin: 0;

  border-radius: $height / 2;
  box-shadow: 0px 0px 1px 0px rgba(0 0 0 / 0.10);
  background-color: colors.$white;
}

.scrollIndicatorItem {
  $size: 6px;
  font-size: 0;
  width: $size;
  height: $size;
  border-radius: $size / 2;
  background-color: colors.$purple500;
  transition: width 250ms ease-out, background-color 250ms ease-out;

  &.current {
    width: 32px;
  }
  &:not(.current) {
    background-color: colors.$neutral200;
  }

}
