@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/fonts";
@use "$styles/variables/colors";
@use "$styles/variables/spacings";
@use "$styles/tools/extends/typography";
@use "$styles/tools/mixins/typography" as typographymixins;
@use '$styles/mediaqueries';

.component {
    position: relative;
    padding: spacings.$M spacings.$S spacings.$XXXS;
    color: colors.$white;
    
    
    // /*** START large psuedo element technique ***/
        padding: spacings.$M spacings.$S (spacings.$XXXS + spacings.$XXXS + spacings.$L);
        @include mediaqueries.mediumUp {
            padding: spacings.$L 0 spacings.$XXXL;
        }
    // /*** END large psuedo element technique ***/
    

    // /*** START clip-path method ***/
    // /**
    // * This method avoids using one single oversized psuedo element with
    // * border-radius to achieve the curve, but rather seperates the section into
    // * two parts with the curve made with 2 separate psuedo-elements that 
    // * are sized so there is no waste in the calculated sizes. It uses a ellipse
    // * clip-path to form the curve; this makes a curve that is less aggressive
    // * than the design, with a flatter bottom edge.
    // * This method would allow the component to use less DOM nodes 
    // **/
    // &.clipPathMethod {
    //     margin-bottom: spacings.$XXXL;
    //     @include mediaqueries.mediumUp {
    //         margin-bottom: spacings.$XXXL;
    //     }
    //     &::before,
    //     &::after {
    //         content: "";
    //         position: absolute;
    //         width: 100%;

    //         background: linear-gradient(90deg, #5739d8, #3a1da7);
    //         @include mediaqueries.mediumUp {
    //             background: linear-gradient(90deg, #5739d8, #3a1da7);
    //         }
    //     }
        
    //     &::before {
    //         top: 0;
    //         left: 0;
    //         height: 100%;
    //     }
    //     &::after {
    //         bottom: 0;
    //         right: 0;
    //         height: 36px;
    //         clip-path: ellipse(50% 48px at 50% -12px);
    //     }

    //     &::after {
    //         content: "";
    //         display: block;
    //         position: absolute;
    //         top: 100%;
    //         left: 50%;
    //         translate: -50% 0;
    //         width: 104%;
    //         height: 36px;
    //         clip-path: ellipse(50% 48px at 50% -12px);
    //         border-radius: 0 0 50% 50%;
    //         @include mediaqueries.mediumUp {
    //             height: 80px;
    //             clip-path: ellipse(50% 64px at 50% -16px);
    //         }
    //     }
    // }
    // /*** END clip-path method ***/
}

.contentContainer {
    position: relative;
    max-width: calc(1280px - (spacings.$L * 4));
    margin: auto;
}

.innerContainer {
    position: relative;
    max-width: 1280px;
    margin: auto;
}

.heading {
    @extend %headlinesH4TypographyStyles;
    font-family: fonts.$campaignFontFamily;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: normal;
    max-width: 542px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: spacings.$XXS;

    @include mediaqueries.mediumUp {
        @include typographymixins.headlinesH2TypographyStyles;
        font-family: fonts.$campaignFontFamily;
    }
}

.tagline {
    @extend %paragraphMTypography;
    text-align: center;
    max-width: 542px;
    margin: 0 auto;
}

.carAsset {
    display: none;

    @include mediaqueries.mediumUp {
        display: block;
        position: absolute;
        left: -202px;
        z-index: 1;
        top: spacings.$L;
        transform: rotate(3.945deg);
    }

    @include mediaqueries.largeUp {
        left: -226px;
    }
}

// /*** START large psuedo element technique ***/
.curvedShapeContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background: linear-gradient(90deg, #5739d8 33%, #3a1da7 66%);
        border-radius: 300vw;
        width: 300vw;
        height: 300vw;
        @include mediaqueries.mediumUp {
            background: linear-gradient(90deg, #5739d8 43%, #3a1da7 57%);
            border-radius: 700vw;
            width: 700vw;
            height: 700vw;
            margin-bottom: spacings.$L;
        }
    }
}
// /*** END large psuedo element technique ***/
