@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';

.component {
  background-color: colors.$white;
}

.heading {
  padding: 0 spacings.$M;
  color: colors.$neutral800;
  text-align: center;
  font-weight: 600;
  @include typography.headlinesH6TypographyStyles;

  @include mediaqueries.mediumUp {
    @include typography.headlinesH5TypographyStyles;
  }
}

.wrapper {
  @include mediaqueries.extraSmallOnly {
    padding: 0;
  }
}

.container {
  padding: spacings.$M 0 0;

  @include mediaqueries.smallUp {
    padding: spacings.$M 0;
  }

  background-color: colors.$white;
}

.hiddenCheckbox {
  display: none;

  &:checked ~ .wrapper {
    .container {
      .secondaryLinksContainer {
        display: grid;
      }
    }
  }
}

.secondaryLinksContainer {
  display: none;
}

.linksRow {
  gap: spacings.$M 0;
  padding: calc(spacings.$S + spacings.$XXXS) spacings.$M;

  @include mediaqueries.mediumUp {
    gap: spacings.$M spacings.$L;
  }

  &.layout2 {
    padding: spacings.$M calc(spacings.$S + spacings.$XXXS) 0;
    gap: 0;

    @include mediaqueries.mediumUp {
      padding: spacings.$M calc(spacings.$S + spacings.$XXXS) spacings.$L;
      gap: spacings.$M spacings.$L;
    }

    :last-child .link {
      border-bottom: none;
    }
  }
}

.link {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: colors.$neutral600;
  @include typography.paragraphSTypography;

  @include mediaqueries.mediumUp {
    @include typography.paragraphMTypography;
  }

  &:hover {
    color: colors.$purple500;
    text-decoration: underline;
  }

  &.layout2 {
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    min-height: 56px;
    height: 100%;
    border-bottom: 1px solid colors.$neutral200;

    @include mediaqueries.mediumUp {
      align-items: flex-start;
      min-height: auto;
      border-bottom: none;
    }
  }
}

.viewAllContainer {
  border-top: 1px solid colors.$neutral200;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewAllToggle {
  cursor: pointer;
  margin: 0;
  color: colors.$purple500;
  text-decoration: underline;
  @include typography.labelMTypographyStyles;

  @include mediaqueries.mediumUp {
    @include typography.paragraphLTypography;
    font-family: Jokker;
    font-weight: 600;
  }

  &:hover {
    text-decoration: none;
  }
}
