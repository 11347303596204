@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/variables/colors";
@use "$styles/variables/fonts";
@use "$styles/variables/elevation";
@use '$styles/variables/spacings';

.component {
    z-index: elevation.$top;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.primary {
    background: linear-gradient(90deg, #00863A 0%, #019642 5%, #019642 95%, #00863A 100%);
    color: colors.$white;
    padding: 16px 20px;
    align-items: center;
    font: fonts.$labelM;
    @include mediaqueries.mediumUp {
        font: fonts.$headlinesH6;
    }
}

.secondary {
    background: colors.$purple100;
    color: colors.$purple800;
    padding: spacings.$XXS;
    font: fonts.$paragraphXS;
    @include mediaqueries.mediumUp {
        padding: spacings.$XS;
        font: fonts.$paragraphM;
        font-weight: 500;
    }
}
