@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/fonts';
@use '$styles/variables/colors';
@use '$styles/variables/spacings';

.component {
  display: flex;
  font: fonts.$labelM;

  .showAllButton {
    display: flex;
    gap: spacings.$XXS;
    cursor: pointer;
  }
  .showAllText {
    font: fonts.$labelXS;
    color: var(--links-color, colors.$neutral800);
    text-decoration: underline;
  }
  .showAllIcon {
    color: var(--links-color, colors.$neutral800);
  }
}

.card {
  justify-content: center;
  align-items: center;
  border-top: 1px solid colors.$neutral200;
  .showAllButton {
    align-items: center;
  }
}

.label {
  justify-content: flex-start;
  align-items: start;
  border-top: 0px;

  .showAllButton {
    align-items: start;

    .showAllText {
      color: colors.$neutral800;
      margin-top: 0px;
    }

    .showAllIcon {
      top: 0px;
      color: colors.$neutral800;
    }
  }
}
