@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/breakpoints';

.component {
  iframe {
    margin: auto;
  }
}

:export {
  smallBreakpoint: breakpoints.$smallBreakpoint;
}