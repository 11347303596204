@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/variables/spacings";
@use "$styles/variables/colors";
@use "$styles/tools/mixins/typography";

.heading {
  margin-bottom: spacings.$S;
  color: colors.$purple800;
  text-align: center;

  @include mediaqueries.mediumUp {
    @include typography.headlinesH2TypographyStyles;
  }
}

.textContainer {
  @include typography.paragraphMTypography;

  color: colors.$purple800;

  > p {
    margin: 0;  
  }
}
