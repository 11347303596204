@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/mediaqueries';

.accordionContainer {
  .cta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    border-radius: 6px;
    background-color: colors.$neutral100;
    padding: 22px 16px;

    @include mediaqueries.mediumUp {
      flex-direction: row;
      justify-content: flex-start;
    }

    .ctaTitle {
      margin-right: 0;
      margin-bottom: 0;
      font-size: 1.06rem;
      line-height: 1.6rem;
      font-weight: 600;
      color: colors.$neutral700;

      @include mediaqueries.mediumUp {
        font-size: 1.6rem;
        line-height: 1.86rem;
        font-weight: 600;
        color: colors.$neutral700;
        letter-spacing: -0.48px;
        margin-right: 8px;
      }
    }

    .ctaLink {
      display: flex;
      align-items: center;
      border-bottom: 2px solid colors.$neutral700;

      @include mediaqueries.mediumUp {
        margin-right: 8px;
      }

      &:hover {
        border-bottom-color: colors.$purple500;
        :global(.Heading) {
          color: colors.$purple500;
        }
        :global(.cta-link-icon) {
          path {
            fill: colors.$purple500;
          }
        }
      }

      :global(.Heading) {
        font-size: 1.6rem;
        line-height: 1.86rem;
        letter-spacing: -0.48px;
        font-weight: 600;
        color: colors.$neutral700;
        margin-bottom: 0;
        white-space: nowrap;
      }

      :global(.cta-icon) {
        margin-left: 6px;
      }
    }
  }
}

.collapseTitleWrapper {
  display: flex;
  flex-direction: row;

  .collapseTitleText {
    font-family: Jokker, sans-serif;
    margin-bottom: 0;
    order: 2;
    font-size: 1.06rem;
    line-height: 1.6rem;
    font-weight: 600;
    color: colors.$neutral800;

    @include mediaqueries.mediumUp {
      font-size: 1.46rem;
      line-height: 1.86rem;
      font-weight: 600;
      letter-spacing: -0.44px;
      margin-bottom: 0;
    }
  }

  .collapseTitleIcon {
    margin-right: 12px;
    width: 28px;
    height: 28px;
  }
}

.content {
  font-family: 'Rubik', sans-serif;
  font-size: 1.06rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: colors.$neutral700;
  padding-top: 4px;

  @include mediaqueries.mediumUp {
    padding-top: 16px;
  }
}

:export {
  neutral100: colors.$neutral100;
  neutral600: colors.$neutral600;
  white: colors.$white;
}