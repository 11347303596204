@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/elevation';
@use '$styles/mediaqueries';

.topAdjustment {
  @include mediaqueries.smallUp {
    &:global(.top) {
      padding-top: calc(80px + 40px);
      margin-top: -80px;
    }
  }
}

@include mediaqueries.extraSmallOnly {
  .stickyCTA {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: elevation.$top;
    padding: 20px 16px;
    background-color: colors.$white;
    box-shadow: 0 1px 16px 0 rgba(0 0 0 / 0.25);
    button {
      width: 100%;
    }
  }

  .toggleButton {
    width: 100%;
  }
}

