@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/spacings";
@use "$styles/tools/mixins/typography";
@use "$styles/mediaqueries";

.component {
    background-color: var(--bg-color);

    @include mediaqueries.upToMedium {
        table {
            width: calc(100% + 40px);
            translate: -20px 0;
            overflow: hidden;
        }
    }
}

.ctas {
    display: flex;
    justify-content: center;
    margin: spacings.$M auto 0;
}

.ctasContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background-color: colors.$neutral200;
        position: absolute;
        left: 0;
        top: 52px + spacings.$S;
    }
}

.toggleButton {
    margin-right: 0 !important;
}

.orLabel {
    @include typography.paragraphSTypography;
    text-transform: uppercase;
    padding: 0 spacings.$XXXS / 2;
    margin: spacings.$XXS auto spacings.$XXXS;
    color: colors.$neutral400;
    background-color: var(--bg-color);
    z-index: 1;
}