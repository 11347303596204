@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/spacings";
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";


.cardImgWrapper {
  @include mediaqueries.upToMedium {
    order: 1;
    height: 60px;
  }

  position: relative;
  width: 100%;
  height: 120px;

  img {
    @include mediaqueries.upToMedium {
      object-position: center;
    }

    object-fit: contain;
    object-position: right;
    height: 100%;
    width: auto;
  }
}

.cardWrapper {
  margin-top: 16px;
}

.card {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px colors.$brandLightGray;
}

.cardHeader {
  padding: spacings.$M;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid colors.$brandLightGray;
  flex-direction: column;

  @include mediaqueries.mediumUp {
    flex-direction: row;
  }
}

.cardTitleWrapper {
  @include mediaqueries.upToMedium {
    order: 2;
  }
}


.woSection {
  color: colors.$purple500;
  @include typography.paragraphLTypography;
  margin-bottom: spacings.$XXXS;
}

.woTitle {
  @include typography.headlinesH5TypographyStyles;
  margin-bottom: 0;

  @include mediaqueries.mediumUp {
    margin-bottom: spacings.$XXXS;
    
  }
}

.woPrice {
  @include typography.paragraphMTypography;
}

.woImg {
  img {
    max-width: 180px;
    height: 120px;
  }
}

.cardContent {
  padding: spacings.$M;

}

.serviceSection {
  margin-bottom: spacings.$S;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @include typography.paragraphSTypography;
    letter-spacing: 0;
    margin-bottom: 0;

    @include mediaqueries.mediumUp {
      @include typography.paragraphMTypography;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }
}

.cardFooter {
  margin-top: auto;
  padding: 0 spacings.$M spacings.$M;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainCta {
  display: flex;
  @include typography.paragraphSTypography;
  background: none;
  color: colors.$purple500;
  text-decoration: none;

  img {
    margin-left: spacings.$XS;
  }
}
