@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use "$styles/variables/radii";
@use "$styles/variables/spacings";
@use "$styles/mediaqueries";
@use "$styles/tools/mixins/typography";

.component {

  h2 {
    @include typography.headlinesH4TypographyStyles;
    margin-bottom: spacings.$XXS;

    @include mediaqueries.mediumUp {
      @include typography.headlinesH2TypographyStyles;
      margin-bottom: spacings.$M;
      max-width: 900px;
    }
  }

  p {
    @include typography.paragraphMTypography;
    margin-top: spacings.$L;
    margin-bottom: spacings.$L;

    @include mediaqueries.mediumUp {
      margin-top: spacings.$M;
      margin-bottom: spacings.$M;
      max-width: 820px;
    }
  }

  table {
    @include typography.paragraphSTypography;
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    @include mediaqueries.mediumUp {
      @include typography.paragraphMTypography;
      border: 1px solid colors.$neutral200;
      border-radius: radii.$M;
    }

    >tbody {
      >tr {
        &:nth-child(even) {
          >td {
            background-color: colors.$purple100;
          }
        }

        @include mediaqueries.mediumUp {
          &:first-child {
            >th {
              &:first-child {
                border-top-left-radius: radii.$M;
              }

              &:last-child {
                border-top-right-radius: radii.$M;
              }
            }

          }

          &:last-child {

            >th,
            >td {
              &:first-child {
                border-bottom-left-radius: radii.$M;
              }

              &:last-child {
                border-bottom-right-radius: radii.$M;
              }
            }
          }

        }
      }
    }


    th {
      box-sizing: content-box;
      height: 48px;
      background-color: colors.$purple500;
      color: colors.$white;

      p {
        @include typography.labelSTypographyStyles;
        margin: 0;

        @include mediaqueries.mediumUp {
          @include typography.labelMTypographyStyles;
        }
      }
    }

    td {
      box-sizing: content-box;
      height: 24px;
      background-color: colors.$white;

      p {
        @include typography.paragraphSTypography;
        margin: 0;

        @include mediaqueries.mediumUp {
          @include typography.paragraphMTypography;
        }
      }
    }

    th,
    td {
      padding: spacings.$XXS spacings.$M;
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }
  }
}