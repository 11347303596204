@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';

.component {
  :global(.GoogleReviews h2) {
    display: none;
  }
}

.GaragePageContent {
  padding-top: spacings.$M;
  padding-bottom: spacings.$M;

  @include mediaqueries.upToMedium {
    padding-bottom: 0px;
    position: relative;
    margin-bottom: -60px;
    z-index: 2;

    display: flex;
    flex-direction: column;

    .CTAListColumn {
      order: 3;
    }
    .AboutUsColumn {
      order: 1;
    }
    .LocationColumn {
      order: 2;
    }
  }
}

.GarageAboutUsCardComponent {
  @include mediaqueries.upToMedium {
    margin-top: -90px;
    margin-bottom: spacings.$XS;
    order: 1;
  }

  @include mediaqueries.mediumUp {
    box-shadow: unset !important;
    margin-bottom: spacings.$XXXL;
  }
}


.GarageServicesCTAListComponent {
  @include mediaqueries.upToMedium {
    order: 2;
    margin-bottom: spacings.$XS;
  }

  @include mediaqueries.mediumUp {
    margin-top: -90px;
    margin-bottom: spacings.$XL;
    position: relative;
  }
}

.GarageLocation {
  @include mediaqueries.upToMedium {
    margin-bottom: spacings.$XS;
  }

  @include mediaqueries.mediumUp {
    margin-bottom: -100px !important;
    display: flex;
    gap: spacings.$XXXL;
    box-shadow: unset;
    border: 1px solid colors.$neutral200;
  }
}

.desktop {
  @include mediaqueries.upToMedium {
    display: none;
  }
}

.mobile {
  @include mediaqueries.mediumUp {
    display: none;
  }
}