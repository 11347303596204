@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use '$styles/mediaqueries';

.modal {
  .content {
    font-size: 1.06rem;
    line-height: 1.6rem;
    font-weight: 400;
    color: colors.$neutral400;

    @include mediaqueries.smallUp {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .cta {
    flex-wrap: wrap;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 16px;
    
    @include mediaqueries.smallUp {
      align-items: center;
      flex-direction: row;
    }

    .Button {
      flex: 1 1 auto;

      @include mediaqueries.smallUp {
        flex-grow: 0;
      }
    }
  }
}
