@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/values';
@use '$styles/tools/mixins/typography';

.component {
  z-index: values.$elevation1;
  background: values.$colorWhite;
  overflow-x: clip;
}
.Col {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.heading {
  text-align: center;
  color: values.$colorNeutral800;
  margin-bottom: values.$space6;
  @include mediaqueries.mediumUp {
    @include typography.headlinesH2TypographyStyles;
    margin-bottom: values.$space12;
  }
  u {
    text-decoration: none;
    position: relative;

    &::after {
      content: url('/img/svg/squiggle-large.svg');
      position: absolute;
      left: 50%;
      translate: -42% 0;
      top: calc(50% + 6px);
      @include mediaqueries.upToMedium {
        transform: scale(0.68);
        translate: -48% 0;
        top: calc(50% + 2px);
      }
    }
  }
}
.topElement {
  .heading {
    @include mediaqueries.smallUp {
      padding-top: 40px;
    }
  }
  .Col {
    margin-bottom: 80px;
  }
}
.card {
  @include mediaqueries.extraSmallOnly {
  }
  @include mediaqueries.smallOnly {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - values.$space4);
  }
  @include mediaqueries.mediumOnly {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - values.$space4);
  }
  @include mediaqueries.smallUp {
    background: rgba(values.$colorPurple100, 0.5);
    padding: values.$space5 values.$space4;
    border-radius: values.$space4;
  }
  @include mediaqueries.largeUp {
    flex-basis: 25%;
  }
}
.cards {
  text-align: center;
  display: flex;
  gap: values.$space8;
  flex-direction: row;
  @include mediaqueries.upToSmall {
    background: rgba(values.$colorPurple100, 0.5);
    border-radius: values.$radius6;
    padding: values.$space10;
    flex-direction: column;
  }
  @include mediaqueries.extraSmallOnly {
  }
  @include mediaqueries.smallOnly {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @include mediaqueries.mediumOnly {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  :nth-child(2) {
    .icon {
      &.en {
        :after {
          content: '£';
          padding-left: 8px;
        }
      }
      &.fr {
        :after {
          content: '€';
          padding-left: 7px;
        }
      }
      :after {
        position: absolute;
        color: values.$colorWhite;
        font-weight: bold;
        font-size: 11px;
        padding-top: 2px;
        font-family: sans-serif;
      }
    }
  }
}
.uspTitle {
  display: block;
  @include typography.labelMTypographyStyles;
  color: values.$colorPurple800;
}
.uspContent {
  color: values.$colorNeutral400;
  @include typography.paragraphSTypography;
  margin: 0;
  margin-top: values.$space2;
}
.image {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(43px, 243px);
  @include mediaqueries.smallUp {
    transform: translate(145px, 250px);
  }
  @include mediaqueries.extraSmallOnly {
    mask-image: linear-gradient(to bottom, rgb(0, 0, 0) 60%, rgba(0, 0, 0, 0.5) 62%, rgba(0, 0, 0, 0) 68%);
  }
}
.yellowCar {
  transform: scale(0.7) rotate(-9deg);
  @include mediaqueries.smallUp {
    transform: scale(0.87) scaleX(-1) rotate(-18deg);
  }
  @include mediaqueries.mediumUp {
    transform: scaleX(-1) rotate(-18deg);
  }
}
.whyFixterTag {
  display: inline-flex;
  gap: values.$space2;
  height: 35px;
  padding-right: values.$space2;
  color: values.$colorWhite;
  @include typography.labelMTypographyStyles;
  border-radius: values.$radius3;
  background-color: values.$colorPurple500;
  transform: rotate(-2.492deg);
  margin: values.$space1 auto values.$space3 auto;
  &.hideTag {
    margin-top: 40px;
  }
  @include mediaqueries.smallUp {
    margin-bottom: values.$space4;
    &.hideTag {
      display: none;
    }
  }
}
.logo {
  flex: 0 0 auto;
  padding-left: values.$space2-5;
  padding-top: values.$space2;
  background-color: values.$colorPurple700;
  width: 35px;
  height: 100%;
  border-top-left-radius: values.$radius3;
  border-bottom-left-radius: values.$radius3;
}
.whyFixter {
  flex: 0 0 auto;
  line-height: 35px;
}
.icon {
  display: inline-flex;
  margin-bottom: values.$space3;
  justify-content: center;
  align-items: center;
  width: values.$space12;
  height: values.$space12;
  border-radius: 200px;
  background: linear-gradient(143deg, values.$colorBrandPurple 22.33%, #402c99 115.7%);
  box-shadow:
    0px 0px 1px 0px rgba(2, 13, 39, 0.25),
    0px 1px 4px 0px rgba(2, 13, 39, 0.08);
  svg {
    color: values.$colorWhite;
  }
}
