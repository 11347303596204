@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/variables/radii';
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';

.cardHeader {
  @include mediaqueries.mediumUp {
    margin-right: spacings.$XXXL;
  }

  .heading {
    @include typography.headlinesH4TypographyStyles;

    @include mediaqueries.smallUp {
      @include typography.headlinesH3TypographyStyles;
    }
  }
}

.checklistTitle {
  @include typography.paragraphLTypography;
  font-weight: 600;
}

.productDescription {
  @include typography.paragraphLTypography;
  white-space: pre-line;
}

.tableContainer {
  overflow: hidden;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  td {
    padding: spacings.$XS;
    border: 1px solid colors.$neutral200;
    vertical-align: middle;
    @include typography.paragraphLTypography;
    border-bottom: 0;
    &:first-child {
      border-right: 0;
    }
  }
  tr:nth-child(even) {
    background-color: colors.$neutral100;
  }
  tr:nth-child(odd) {
    background-color: colors.$white;
  }

  tr td:last-child {
    text-align: center;
    line-height: 100% !important;
    height: 100%;
  }
  tr:first-child td {
    &:first-child {
      border-top-left-radius: radii.$S;
    }
    &:last-child {
      border-top-right-radius: radii.$S;
    }
  }
  tr:last-child td {
    border-bottom: 1px solid colors.$neutral200;
    &:first-child {
      border-bottom-left-radius: radii.$S;
    }
    &:last-child {
      border-bottom-right-radius: radii.$S;
    }
  }
}

.checkColumn {
  text-align: center;
  width: 56px;

  @include mediaqueries.mediumUp {
    width: 178px;
  }
}

.brandsContainer {
  margin-top: spacings.$L;
}

.brandImagesContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.brandsHeading {
  @include typography.paragraphMTypography;
  font-weight: 600;
}

.brandImageWrapper {
  position: relative;
  height: 50px;
  flex-shrink: 0;
  margin: 0 spacings.$XXXS;
}

.brandImage {
  object-fit: contain;
}

:export {
  colorGreen300: colors.$green300;
}
