@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/radii';
@use '$styles/variables/shadows';
@use '$styles/variables/spacings';
@use '$styles/variables/breakpoints';
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';

.component {
  background-color: colors.$white;
  text-align: left;

  @include mediaqueries.upToExtraLarge {
    padding-left: 0;
    padding-right: 0;
  }

  @include mediaqueries.upToMedium {
    padding-top: spacings.$XXL;
    padding-bottom: spacings.$L;
  }

  @include mediaqueries.mediumUp {
    padding-top: spacings.$XXXL;
    padding-bottom: spacings.$XL;
  }

  :global(.slick-arrow) {
    background-color: #f8f5ff;
    z-index: 2;
  }

  :global(.review-summary > a) {
    margin-bottom: spacings.$XL;
    justify-content: center;

    @include mediaqueries.upToSmall {
      flex-direction: column;
    }
  }
}

.headings {
  text-align: center;
  color: colors.$neutral800;

  .Heading {
    @include typography.headlinesH3TypographyStyles;
    margin-bottom: spacings.$XS;

    @include mediaqueries.smallUp {
      @include typography.headlinesH2TypographyStyles;
      margin-bottom: spacings.$M;
    }
  }

  .Subheading {
    @include typography.headlinesH6TypographyStyles;
    color: colors.$neutral800;
    margin-bottom: spacings.$M;
    text-align: center;
    font-weight: 400;
  }
}

.bleed {
  padding-left: 0;
  padding-right: 0;
}

.trustpilot {
  height: 40px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: spacings.$S;

  @include mediaqueries.smallUp {
    height: 20px;
    max-width: 100%;
    margin-botton: spacings.$L;
  }
}

.carousel {
  :global(.slick-slider) {
    padding-bottom: 76px !important;

    @include mediaqueries.mediumUp {
      padding-bottom: 72px !important;
    }
  }

  :global(.slick-slide) {
    padding: 0 spacings.$XXS;
   
    @include mediaqueries.smallUp {
      padding: 0 spacings.$S;
    }
    @include mediaqueries.extraSmallOnly {
      width: calc(100vw - 40px);
    }
  }
  :global(.slick-current) {
    @include mediaqueries.extraSmallOnly {
      div {
        min-width: 200px;
      }
    }
  }
}

.review {
  margin-top: spacings.$XXS;
  display: flex !important;
  flex-direction: row;
  gap: spacings.$M;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: #fff;

  border-radius: radii.$M;
  box-shadow: shadows.$elevationLevel2BoxShadow;

  padding: spacings.$M spacings.$S;
  height: inherit;
}

.reviewBody {
  display: flex;
  flex-direction: column;
  gap: spacings.$XS;
}

.starRating {
  height: 24px;
}

.reviewHeading {
  color: colors.$neutral800;
  margin: 0;
}

.reviewText {
  @include typography.paragraphSTypography;
  color: colors.$neutral600;
  // NOTE: Non-standard! mitigates long reviews breaking layout.
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.author {
  color: colors.$neutral800;
  align-self: flex-end;
}
