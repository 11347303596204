@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";
@use "$styles/variables/colors";
@use "$styles/variables/spacings";

.component {
    &:global(.modalContent) {
        display: flex;
        flex-direction: column;
        row-gap: spacings.$S;
        padding: 0 24px 24px 24px;
        @include mediaqueries.smallUp {
            padding: 8px;
            width: 456px - 40px;  // 456px minus the surrounding margins and paddings of the components
        }

        > .title {
            @include typography.headlinesH5TypographyStyles;
            order: -1;
        }

        > .badge {
            margin-bottom: 0;
        }
    }

    // @NOTE aligned to the grid for use with CMS
    &:global(.cmsColumn) {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: span 4;


        @include mediaqueries.smallUp {
            grid-column: span 6;
        }
        @include mediaqueries.mediumUp {
            grid-column: span 12;
        }

        > .badge,
        > .title,
        > .introDescription,
        > .guaranteeCard {
            grid-column: span 4;

            @include mediaqueries.smallUp {
                grid-column: span 6;
            }

            @include mediaqueries.mediumUp {
                grid-column: span 4;
            }
        }
        
        > .introDescription {
            margin-bottom: 0;
        }
        > .guaranteeCard {
            margin: spacings.$M 0 0;
        }

        @include mediaqueries.mediumUp {
            > .badge {
                margin-left: 0;
                margin-bottom: 0;
            }
            > .badge,
            > .title,
            > .introDescription {
                grid-column: 1 / span 4;
            }

            > .title, .introDescription {
                margin: spacings.$S 0 0;
            }

            > .guaranteeCard {
                min-height: 108px;
                grid-column-end: span 4;
                &:nth-of-type(odd) {
                    grid-column-start: 5;
                }
                &:nth-of-type(even) {
                    grid-column-start: 9;
                }

                &:nth-of-type(-n+2) {
                    grid-row: 1 / span 1;
                }
                &:nth-of-type(n+3) {
                    grid-row: 2 / span 2;
                }
            }
        }
    }
}

.badge {
    display: block;
    margin: auto auto spacings.$S;
}
.title {
    margin: 8px 0;
    color: colors.$neutral800;

    @include mediaqueries.upToMedium {
        text-align: center;
    }
}
.introDescription {
    margin: spacings.$XXS auto spacings.$M;
    color: colors.$neutral600;
    @include mediaqueries.upToMedium {
        text-align: center;
    }
}
.top {
    display: flex;
    align-items: center;
    color: colors.$neutral600;
}
.guaranteeCard {
    margin: 0;
    padding: spacings.$S spacings.$XS;
    border: 1px solid colors.$green200;
    background: colors.$green100;
    box-shadow: none;
}
.name {
    margin-left: 10px;
    @include typography.labelMTypographyStyles;
    color: inherit;
    line-height: 20px;
    letter-spacing: -0.16px;
}
.description {
    color: colors.$neutral400;
    margin-block-start: 10px;
    margin-block-end: 0;
    margin-inline-start: 34px;
    line-height: 20px;
    font-size: 14px;
}